import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import {motion} from 'framer-motion'
import Header from 'components/Home/Header/Header'
import ContactUs from 'components/Home/ContactUs/ContactUs'
import Footer from 'components/Global/Layout/Footer/Footer'
import WhatOurClientsSay from 'components/Home/WhatOurClientsSay/WhatOurClientsSay'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { axiosConfig } from 'utils/axiosConfig'
import AboutUs from 'components/Home/AboutUs/AboutUs'
import WhatWeOffer from 'components/Home/WhatWeOffer/WhatWeOffer'
import OurProjects from 'components/Home/OurProjects/OurProjects'
import MeetOurTeam from 'components/Home/MeetOurTeam/MeetOurTeam'
import RequestACallPopUp from 'components/Global/Elements/RequestACallPopUp/RequestACallPopUp'
import SpecialOffer from 'components/Global/Elements/SpecialOffer/SpecialOffer'
import axios from 'axios'
import Loading from 'components/Global/Elements/Loading/Loading'
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup'
import WhyUs from 'components/Home/WhyUs/WhyUs'
function Home() {
  const {t} =useTranslation()
  const selector=useSelector(data=>data.GlobalReducer)
  const [brands,setBrands]=useState([])
  const [types,setTypes]=useState([])
  const [projects,setProjects]=useState([])
  const [reviews,setReviews]=useState([])
  const [categories,setCategories]=useState([])
  const [services,setServices]=useState([])
  const [teamMembers,setTeamMembers]=useState([])
  const [currentCountryCode,setCurrentCountryCode]=useState('+961')
  const [isPageLoading,setIsPageLoading]=useState(true)
  const [isSuccessOpen,setIsSuccessOpen]=useState(false)


  // useEffect(()=>{
  //   setBrands(selector.brands)
  //   setTypes(selector.types)
  // },[selector])
 async function getAllCategories(){
  await axiosConfig.get(`/category/all-categories`).then(res=>{
        setCategories(res.data.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  async function getAllTeamMembers(){
    await axiosConfig.get(`/member/all-members`).then(res=>{
        setTeamMembers(res.data.data)
      }).catch(err=>{
          console.log(err)
      })
  }
 async function getAllProjects(){
      await axiosConfig.get(`/projects/all-projects`).then(res=>{
        setProjects(res.data.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  async function getAllServices(){
    await axiosConfig.get(`/services/all-services`).then(res=>{
        console.log('resss',res)
        setServices(res.data.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  async function getReviews(){
    await axios.get(`https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJq6qqlzFoXz4RrTTTJ6Y3ZDc&key=AIzaSyD2wRNMbf8KYtSRrYbA2-pRqg4jbH3i9Kk`).then(res=>{
        console.log('resss222',res)
        // setReviews(res.data.data)
      }).catch(err=>{
          console.log(err)
      })
  }
  function getIp(){
      axios.get(`https://api.ipify.org?format=json`).then(res=>{
        axios.get(`https://ipapi.co/${res.data.ip}/country_calling_code`).then(resss=>{
          setCurrentCountryCode(resss.data)
          console.log('resss',resss)
        })
      }).catch(err=>{
          console.log(err)
      })
  }
useEffect(()=>{
  window.scrollTo(0,0)
  async function promiseAll(){
    await Promise.all([
      getAllProjects(),
      getAllCategories(),
      getAllTeamMembers(),
      getAllServices(),
      getIp()
    ])
    setIsPageLoading(false)
    // getReviews()
  }
  promiseAll()
},[])
  return (
    <>
    <motion.div 
      init={{width:0}}
      animate={{width:"100%"}}
      exit={{x:window.innerWidth ,transition:{duration:.3}}}
      > 
        <MetaTags>
              <title>WhatsAds</title>
        </MetaTags>
        {isPageLoading&&<Loading/>}
        <RequestACallPopUp currentCountryCode={currentCountryCode} setIsSuccessOpen={setIsSuccessOpen}/>
        {isSuccessOpen&&<SuccessRequestPopup/>}
        <Header categories={categories}/>
        <WhyUs/>
        <AboutUs/>
        <WhatWeOffer categories={categories} />
        <OurProjects projects={projects}/>
        {/* <WhatOurClientsSay/> */}
        {/* <MeetOurTeam teamMembers={teamMembers}/> */}
        <ContactUs currentCountryCode={currentCountryCode} services={services}/>
        <Footer/>
    </motion.div>
    </>
  )
}

export default Home