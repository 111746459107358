import React, { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styles from './SpecialOffer.module.css'
import {ReactComponent as ModalCloseButton} from 'assets/icons/modalCloseButton.svg'
import {ReactComponent as TrueIcon} from 'assets/icons/trueIcon.svg'
import dicontOffer from 'assets/imgs/dicontOffer.png'
import dicontOfferAr from 'assets/imgs/dicontOfferAr.gif'
import dicontOfferEn from 'assets/imgs/dicontOfferEn.gif'
import specialImg from 'assets/imgs/specialImg.png'
import specialImgResponsive from 'assets/imgs/specialImgResponsive.png'
import specialArrow from 'assets/imgs/specialArrow.png'
import specialImg2 from 'assets/imgs/specialImg2.png'
import specialImg3 from 'assets/imgs/specialImg3.png'
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { axiosConfig } from 'utils/axiosConfig';
import { toast } from 'react-toastify';
import ReactLoading from "react-loading";
function SpecialOffer({service}) {
  const {t} =useTranslation()
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading,setIsLoading]=useState(false)
  const [name,setName]=useState('')
  const [email,setEmail]=useState('')
  const [phone,setPhone]=useState('')
  useEffect(()=>{
   
    if (show) {
        document.querySelector('html').classList.add('overflow-hidden')
    } else {
        document.querySelector('html').classList.remove('overflow-hidden')
    }
  },[show])
  useEffect(()=>{
    document.querySelector('html').classList.add('overflow-hidden')
    handleShow()
  },[])
  return (
    <>
      <Modal show={show} onHide={handleClose} size='xl'>
        <div className={styles['modal__request']}>
            <Container >
                <button onClick={handleClose} className={styles['modal__close-button']}>
                    {t('Close')}
                    {/* <ModalCloseButton className={styles['modal__close-button-icon']}/> */}
                </button>
                <Row>
                    <Col lg='6'>
                        <img src={dicontOffer} className={styles['modal__img']} alt='modal image'/>
                        <img src={dicontOffer} className={styles['modal__img--arabic']} alt='modal image'/>
                        {/* <img src={dicontOfferEn} className={styles['modal__img--responsive']} alt='modal image'/> */}
                        {/* <div className={`${styles['arrow-wrapper']} d-flex`}>
                            <img src={specialArrow} className={styles['modal__img-arrow']} alt='modal image'/>
                        </div> */}
                    </Col>
                    <Col lg='6'>
                        <div>
                            <h1 className={styles['modal__title']}>
                                {service?.name}
                            </h1>
                            <h2 className={styles['modal__sub-title']}>{t('Custom')} {service?.name} {t('Package')}</h2>
                            <p className={styles['modal__price']}>{service?.price_after_discount} {t('USD')}</p>
                            <p className={styles['modal__discount']}><p className={styles['modal__discount-span']}>{service?.price_before_discount} {t('USD')}</p></p>
                            <ul className={styles['modal__list']}>
                                {
                                    service?.offer_features&&service?.offer_features.map((featuree,index)=>(
                                        <li className={styles['modal__list-item']} key={index}>
                                            <TrueIcon className={styles['modal__icon']}/> {featuree?.feature}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </Modal>
    </>
  )
}

export default SpecialOffer