import React from 'react'
import { Container } from 'react-bootstrap'
import styles from './MeetOurTeam.module.css'
import teamMember1 from 'assets/imgs/teamMember1.jpg'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination ,Autoplay} from "swiper";
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactLoading from "react-loading";
function MeetOurTeam({teamMembers}) {
  const {t} =useTranslation()
  return (
    <section className={styles['meet-our-team']}>
        <Container>
            <h2 data-aos="new-animation" className={styles['meet-our-team__title']}>{t('Meet our team')}</h2>
            <p className={styles['meet-our-team__description']} data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800'>{t('The experience, talent and dedication of everyone on our team is what makes us unique. Pavilion Technology is a dynamic and rapidly growing environment at all levels, where ambition meets teamwork in a renewed attempt to meet challenges and achieve success and progress.')}</p>
            <div>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={5}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    }}
                    breakpoints={{
                        0: {
                            slidesPerView:2,
                            spaceBetween: 20,
                        },
                        426:{
                            slidesPerView:3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 40,
                        },
                        1200: {
                            slidesPerView: 5,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[Autoplay]}
                    className={`${styles['meet-our-team__swiper']} meet-our-team__swiper`}
                    data-aos='fade-up' data-aos-duration='800' data-aos-delay='300' data-aos-once='true'
                >
                    {
                        teamMembers&&teamMembers.map((member,index)=>(
                            <SwiperSlide key={index}>
                                <div className={styles['meet-our-team__member-wrapper']}>
                                    <img src={member?.image} alt ='member' className={styles['meet-our-team__member-img']}/>
                                    
                                    <h3 className={styles['meet-our-team__member-content-title']}>{member?.name}</h3>
                                    <h4 className={styles['meet-our-team__member-content-sub-title']}>{member?.job_title}</h4>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </Container>
    </section>
  )
}

export default MeetOurTeam