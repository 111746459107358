import React,{ useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { ToastContainer } from 'react-toastify';
import Home from "views/Home/Home";
import NavBar from 'components/Global/Layout/NavBar/NavBar';
import SideBar from 'components/Global/Layout/SideBar/SideBar';
import ErrorPage from 'views/ErrorPage/ErrorPage';
import { axiosConfig } from 'utils/axiosConfig';
import Loading from 'components/Global/Elements/Loading/Loading';
import NavigateToTop from 'components/Global/Elements/NavigateToTop/NavigateToTop';
import { useReducer } from 'react';
import { changeAllCarsAction, changeBrandAction, changeGeneralSettingsAction, changeTypeAction } from 'reduxStore/Global/GlobalActions';
import { useDispatch, useSelector } from 'react-redux';
import ChatItem from 'components/Global/Elements/ChatItem/ChatItem';
import SuccessRequestPopup from 'components/Global/Elements/SuccessRequestPopup/SuccessRequestPopup';
import { Loader } from "@googlemaps/js-api-loader"
function AnimatedRoutes() {
    const [isSideBarVisible , setIsSideBarVisible] =useState(false)
    const [isMainNavBarWillApear,setIsMainNavBarWillApear] =useState(false)
    const [isRouteExist,setIsRouteExist] =useState(false)
    const [isNavbarHide,setIsNavbarHide] =useState(false)
    const [isNavbarFixed,setIsNavbarFixed] =useState(false)
    const [isLoadingVisible,setIsLoadingVisible]=useState(false)
    const dispatch= useDispatch()
    const location = useLocation()
    let toggleSideNavBar =(type)=>[
      setIsSideBarVisible(type==='open')
    ]
      const pagesWithoutNavbar = ['all-cars','car-details']
      const websiteRoutes = ['all-cars','car-details','blogs','contact-us','rental-policy','deals','blog']
      // const loader = new Loader({
      //   apiKey: "AIzaSyD2wRNMbf8KYtSRrYbA2-pRqg4jbH3i9Kk",
      //   version: "weekly",
      // });
      // loader.load()
      // loader.load().then(() => {
      //   map = new google.maps.Map(document.getElementById("map"), {
      //     center: { lat: -34.397, lng: 150.644 },
      //     zoom: 8,
      //   });
      // });
      // useEffect(()=>{
      //   setIsMainNavBarWillApear( location.pathname.split('/').some(path=>pagesWithoutNavbar.includes(path)))
      //   if(location.pathname=='/'){
      //     setIsRouteExist( true )
      //   }else{
      //     setIsRouteExist( location.pathname.split('/').some(path=>websiteRoutes.includes(path)) )
      //   }
      // },[location]) 
      // useEffect(()=>{
      //   let scroll = 0
      //   function toggleNavbarWhileScroll(){
      //     if(window.scrollY >1 ){
      //       setIsNavbarFixed(true)
      //       if(window.scrollY >scroll){
      //         setIsNavbarHide(true)
      //       }else{
      //         setIsNavbarHide(false)
      //       }
      //     }else{
      //       setIsNavbarFixed(false)
      //     }
      //     scroll =window.scrollY
      //   }
      //   let windowScroll =window.addEventListener('scroll',toggleNavbarWhileScroll)
      //   return ()=>window.removeEventListener('scroll',windowScroll)
      // },[])

      // const [brands,setBrands]=useState([])
      // const [types,setTypes]=useState([])
      // function getAllBrands(){
      //     axiosConfig.get(`/brands/all-brands`).then(res=>{
      //       setBrands(res.data.data)
      //       // changeBrandAction(dispatch,res.data.data)
      //       dispatch(changeBrandAction(res.data.data))
      //     }).catch(err=>{
      //         console.log(err)
      //     })
      // }
      // function getAllTypes(){
      //     axiosConfig.get(`/types/all-types`).then(res=>{
      //       // changeTypeAction(dispatch,res.data.data)
      //       setTypes(res.data.data)
      //       dispatch(changeTypeAction(res.data.data))
      //     }).catch(err=>{
      //         console.log(err)
      //     })
      // }
      function getGeneralSettings(){
          axiosConfig.get(`/settings`).then(res=>{
            dispatch(changeGeneralSettingsAction(res.data.data))
          }).catch(err=>{
              console.log(err)
          })
      }
      // function getAllCars(){
      //     axiosConfig.get(`/cars/all-cars`).then(res=>{
      //       dispatch(changeAllCarsAction(res.data.data))
      //     }).catch(err=>{
      //         console.log(err)
      //     })
      // }
      useEffect(()=>{
        // getAllBrands()
        // getAllTypes()
        getGeneralSettings()
        // getAllCars()
      },[])
      useEffect(()=>{
        let load = document.addEventListener('load',()=>{
          setIsLoadingVisible(true)
        })
        return ()=>document.removeEventListener('load',load)
      },[])
      useEffect(()=>{
        console.log(location)
        toggleSideNavBar('close')
      },[location])
  return (
    <>
      {isLoadingVisible&&<Loading/>}
      <NavigateToTop/>
      <NavBar 
        toggleSideNavBar={()=>{toggleSideNavBar('open')}} 
        // isSidePage={isMainNavBarWillApear} 
        // isRouteExist={isRouteExist} 
        // isNavbarFixed={isNavbarFixed} 
        // isNavbarHide={isNavbarHide}
        // brands={brands}
        // types={types}
      />
      <SideBar 
        isSideBarVisible={isSideBarVisible} 
        toggleSideNavBar={()=>{toggleSideNavBar('close')}}
        // types={types}
        // brands={brands}
      />
      <ChatItem/>
      <div className="App">
      <ToastContainer />
      <AnimatePresence>
          <Routes location={location} key={location.pathname}>
              <Route path='/' exact element={<Home/>}></Route>
              <Route path='*' element={<ErrorPage/>}></Route>
          </Routes>
      </AnimatePresence>
        </div>
    </>
  )
}

export default AnimatedRoutes