import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './Footer.module.css'
import whatsAppFooterLogo from 'assets/imgs/LogoWhatsAdsW.png'
import logoResponsive from 'assets/imgs/LogoWhatsAdsW.png'
import {ReactComponent as TwitterIconColored} from 'assets/icons/twitterIconColored.svg'
import {ReactComponent as InstaColoredIcon} from 'assets/icons/instaColoredIcon.svg'
import {ReactComponent as FaceBookColoredIcon} from 'assets/icons/faceBookColoredIcon.svg'
import {ReactComponent as LinkedInColoredIcon} from 'assets/icons/linkedInColoredIcon.svg'
import {ReactComponent as TiktokColoredIcon} from 'assets/icons/tiktokColoredIcon.svg'
import {ReactComponent as BehanceDarkIcon} from 'assets/icons/behanceDarkIcon.svg'
import {ReactComponent as Google} from 'assets/icons/google.svg'
import {ReactComponent as StarIcon} from 'assets/icons/starIcon.svg'
import {ReactComponent as MessageIconn} from 'assets/icons/messageIcon.svg'
import {ReactComponent as PhoneIconn} from 'assets/icons/phoneIconn.svg'
import {ReactComponent as PinIconn} from 'assets/icons/pinIconn.svg'
import {ReactComponent as FacebookIcon} from 'assets/icons/facebookIcon.svg'
import {ReactComponent as InstgramIcon} from 'assets/icons/instgramIcon.svg'
import {ReactComponent as LinkedInIcon} from 'assets/icons/linkedInIcon.svg'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { emailConnect, whtsAppConnect } from 'utils/features'
import './Footer.css'
import  PinImg from 'assets/imgs/pinImg.png'
import PhoneiImg from 'assets/imgs/phoneImg.png'
import MessageImg from 'assets/imgs/messageImg.png'
import { HashLink } from 'react-router-hash-link'
function Footer() {
    const {t} =useTranslation()
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
    },[selector])
  return (
    <footer id='footer' data-aos-duration='800' data-aos-once='true'>
        <Container >
            <Row>
                <Col lg='5' md='6' sm='12' className={`${styles['footer__list--margin']} p-lg-0 order-1 d-none d-lg-block`}>
                    <img src={whatsAppFooterLogo} alt='logo' className={styles['footer__logo']}  data-aos-once='true' data-aos-delay='100' data-aos-duration='800'/>
                    <p className={styles['footer__description']}  data-aos-once='true' data-aos-delay='200' data-aos-duration='800'>
                        {t("WhatsAds is a marketing Professional platform that is dedicated to helping businesses grow and expand. ")}    
                    </p>
                    <div className={styles['footer__social-icons-wrapper']}  data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>
                        {
                            settings?.twitter_link&&
                            <a href={`${settings?.twitter_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}  data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>
                                <TwitterIconColored className={styles['footer__social-icon']}/>
                            </a>
                        }
                        {
                            settings?.instagram_link&&
                            <a href={`${settings?.instagram_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}  data-aos-once='true' data-aos-delay='200' data-aos-duration='800' >
                                <InstaColoredIcon className={styles['footer__social-icon']}/>
                            </a>
                        }
                        {
                            settings?.facebook_link&&
                            <a href={`${settings?.facebook_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}  data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>
                                <FaceBookColoredIcon className={styles['footer__social-icon']}/>
                            </a>
                        }
                        {
                            settings?.behance_link&&
                            <a href={`${settings?.behance_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}  data-aos-once='true' data-aos-delay='400' data-aos-duration='800'>
                                <BehanceDarkIcon className={styles['footer__social-icon']}/>
                            </a>
                        }
                        {
                            settings?.linkedin_link&&
                            <a href={`${settings?.linkedin_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}  data-aos-once='true' data-aos-delay='500' data-aos-duration='800'>
                                <LinkedInColoredIcon className={styles['footer__social-icon']}/>
                            </a>
                        }
                        {
                            settings?.tiktok_link&&
                            <a href={`${settings?.tiktok_link}`} target='_blank' className={styles['footer__social-icon-wrapper']}  data-aos-once='true' data-aos-delay='600' data-aos-duration='800'>
                                <TiktokColoredIcon className={styles['footer__social-icon']}/>
                            </a>
                        }
                    </div>
                </Col>
                <Col lg='3' md='3' sm='6' className='order-lg-3 order-3 text-center'>
                    <img src={whatsAppFooterLogo} alt='logo' className={`${styles['footers__logo']} ${styles['footer__logo--responsive']} d-flex d-md-none m-auto`}  data-aos-once='true' data-aos-delay='100' data-aos-duration='800'/>
                    {/* <h2 className={styles['footer__section-title']} >{t('quick_links')}</h2> */}
                    <ul className={styles['footer__section-links-list']}>
                        <li className={styles['footer__section-links-item']}  data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>
                            <HashLink smooth to='/#header' className={`${styles['footer__section-link']} footer__section-link`}>{t('Home')}</HashLink>
                        </li>
                        <li className={styles['footer__section-links-item']}  data-aos-once='true' data-aos-delay='4200' data-aos-duration='800'>
                            <HashLink to='/#about-us' className={`${styles['footer__section-link']} footer__section-link`}>{t('About Us')}</HashLink>
                        </li>
                        {/* <li className={styles['footer__section-links-item']}  data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>
                            <HashLink to='/#our-projects' className={`${styles['footer__section-link']} footer__section-link`}>{t('Portfolio')}</HashLink>
                        </li> */}
                        <li className={styles['footer__section-links-item']}  data-aos-once='true' data-aos-delay='400' data-aos-duration='800'>
                            <HashLink to='/#what-we-offer' className={`${styles['footer__section-link']} footer__section-link`}>{t('Services')}</HashLink>
                        </li>
                        {/* <li className={styles['footer__section-links-item']}  data-aos-once='true' data-aos-delay='500' data-aos-duration='800'>
                            <NavLink to='/' className={`${styles['footer__section-link']} footer__section-link`}>{t('Products')}</NavLink>
                        </li>
                        <li className={styles['footer__section-links-item']}  data-aos-once='true' data-aos-delay='600' data-aos-duration='800'>
                            <NavLink to='/' className={`${styles['footer__section-link']} footer__section-link`}>{t('Blogs')}</NavLink>
                        </li> */}
                        <li className={styles['footer__section-links-item']}  data-aos-once='true' data-aos-delay='700' data-aos-duration='800'>
                            <HashLink to='/#contact-us' className={`${styles['footer__section-link']} footer__section-link`}>{t('Contact Us')}</HashLink>
                        </li>
                    </ul>
                </Col>
                <Col lg='4' md='9' sm='6' className='order-lg-3 order-4' >
                    <h2 className={styles['footer__section-title']}  data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>{t('Contact Us')}</h2>
                    <div>
                        <a href={"https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai"} target='_blank' className={styles['contact-us__social-wrapper']}  data-aos-once='true' data-aos-delay='200' data-aos-duration='800'>
                            <div className={styles['contact-us__social-icon-wrapper--responsive']}><PinIconn/></div>
                            <div className={styles['contact-us__social-icon-wrapper']}><img src={PinImg}/></div>
                            <p className={styles['contact-us__social-text']}>{settings?.location?settings?.location:'UAE, Dubai'}</p>
                        </a>
                        <button role='button' onClick={()=>{window.open(`tel:${settings?.contact_phone_number?settings?.contact_phone_number:'971502282086'}`, '_self')}}  className={styles['contact-us__social-wrapper']}  data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>
                            <div className={styles['contact-us__social-icon-wrapper--responsive']}><PhoneIconn/></div>
                            <div className={styles['contact-us__social-icon-wrapper']}><img src={PhoneiImg}/></div>
                            <p className={`${styles['contact-us__social-text']} ${styles['contact-us__social-text--phone']}`}>{settings?.contact_phone_number?settings?.contact_phone_number:'971502282086'}</p>
                        </button>
                        <a href={emailConnect(settings?.contact_email?settings?.contact_email:'info@whatsads.net')}  className={styles['contact-us__social-wrapper']}  data-aos-once='true' data-aos-delay='400' data-aos-duration='800'>
                            <div className={styles['contact-us__social-icon-wrapper--responsive']}><MessageIconn/></div>
                            <div className={styles['contact-us__social-icon-wrapper']}><img src={MessageImg}/></div>
                            <p className={styles['contact-us__social-text']}>{settings?.contact_email?settings?.contact_email:'info@whatsads.net'}</p>
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
        <div className={styles['footer__copywrite']} >
            <p className={styles['footer__copywrite-desc']} >© {new Date().getFullYear()}{t('WhatsAds . All Rights Reserved.')}</p>
        </div>
    </footer>
  )
}

export default Footer