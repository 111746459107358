import OurClientsCard from 'components/Global/Elements/OurClientsCard/OurClientsCard'
import React, { useEffect, useRef } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styles from './WhatOurClientsSay.module.css'
import './WhatOurClientsSay.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {ReactComponent as NextArrowIcon} from 'assets/icons/nextArrowIcon.svg'

function WhatOurClientsSay() {
  const {t} =useTranslation()
  const swiperRef=useRef(null)
  return (
    <section className={`${styles['our-clients']} our-clients`} id='our-clients'>
        <Container>
            {/* <div className={styles['our-clients__title-wrapper']}>
                <h2 className={styles['our-clients__title']} data-aos='fade-up' data-aos-duration='800' data-aos-once='true'>{t('see_what_our_clients')}</h2>
            </div> */}
            <OurClientsCard/>
            {/* <Swiper
                spaceBetween={50}
                slidesPerView={1.2}
                ref={swiperRef}
                className="mySwiper"
                data-aos='fade-up' data-aos-duration='800' data-aos-once='true'
            >
                <SwiperSlide><OurClientsCard/></SwiperSlide>
                <SwiperSlide><OurClientsCard/></SwiperSlide>
                <SwiperSlide><OurClientsCard/></SwiperSlide>
                <SwiperSlide><OurClientsCard/></SwiperSlide>
                <SwiperSlide><OurClientsCard/></SwiperSlide>
                <SwiperSlide><OurClientsCard/></SwiperSlide>
                <SwiperSlide><OurClientsCard/></SwiperSlide>
                <SwiperSlide><OurClientsCard/></SwiperSlide>
                <SwiperSlide><OurClientsCard/></SwiperSlide>
                <SwiperSlide><OurClientsCard/></SwiperSlide>
            </Swiper> */}
            
        </Container>
    </section>
  )
}

export default WhatOurClientsSay