import React, { useEffect, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './AboutUs.module.css'
import {ReactComponent as PlaneIcon} from 'assets/icons/planeIcon.svg'
import {ReactComponent as CloudIcon} from 'assets/icons/cloudIcon.svg'
import {ReactComponent as CirclesIcon} from 'assets/icons/circlesIcon.svg'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import aboutUsImg from 'assets/imgs/aboutUsBg2.jpg'
import ourVisionBg from 'assets/imgs/ourVisionBg.jpg'
import ourVisionBg2 from 'assets/imgs/ourVisionBg2.jpg'
import ourMessageBg from 'assets/imgs/ourMessageBg.jpg'
import ourValuesBg from 'assets/imgs/ourValuesBg.jpg'
import { SectionDiv } from './style'
// const aboutUsImg =require('../../../assets/imgs/aboutUsBg2.jpg')
// const ourVisionBg =require('../../../assets/imgs/ourVisionBg.jpg')
// const ourMessageBg =require('../../../assets/imgs/ourMessageBg.jpg')
// const ourValuesBg =require('../../../assets/imgs/ourValuesBg.jpg')
// https://cataas.com/cat
function AboutUs() {
  const {t} =useTranslation()
  const images = [aboutUsImg,ourVisionBg2,ourMessageBg,ourValuesBg]
  const categoryContent={
    "about_us":{
        title:t('About us'),
        description:t("WhatsAds is a marketing Professional platform that is dedicated to helping businesses grow and expand. We help business owners and marketers reach their target audiences through highly targeted campaigns on WhatsApp. Our team has extensive experience in the world of digital marketing, and we are committed to helping you build a successful business by leveraging the power of WhatsApp. We have been helping businesses reach success . Our platform offers a wide range of features and services from content creation to advertising campaigns and analytics. We strive to provide The best results for your business. Be confident in choosing our services."),
        image:aboutUsImg
    },
    "our_vision":{
        title:t('Our Vision'),
        description:t("We are working to make a change in the world of e-marketing, and we seek to satisfy our customers and be their partners in success."),
        image:ourVisionBg2
    },
    "our_message":{
        title:t('Our Message'),
        description:t("We believe that we are partners to our clients in success, which means their success is ours so we work hard to develop our skills and our services according to our clients’ demands."),
        image:ourMessageBg
    },
    "our_values":{
        title:t('Our Values'),
        description:t("We seek excellence so we always create innovative solutions to achieve success, in addition, we are known for our honesty and mastery."),
        image:ourValuesBg
    },
  }
  const[title,setTitle]=useState(categoryContent[`about_us`]?.title)
  const[description,setDescription]=useState(categoryContent[`about_us`]?.description)
  const[image,setImage]=useState(categoryContent[`about_us`]?.image)
  const[activeCategory,setActiveCategory]=useState('about_us')
    function changeActiveCategory(changedCategory){
        changedCategory = changedCategory==activeCategory?'about_us':changedCategory
        setActiveCategory(changedCategory)
        setTitle(categoryContent[`${changedCategory}`]?.title)
        setDescription(categoryContent[`${changedCategory}`]?.description)
        setImage(categoryContent[`${changedCategory}`]?.image)
    }
    useEffect(()=>{
        images?.forEach(image=>{
            let img = new Image()
            img.src= image
            console.log('11111111111111111',img)
        })
    },[...images])
  return (
    // <SectionDiv bg={image} className={`${styles['about-us']}`} id='about-us'>
    <section className={`${styles['about-us']} 
    // ${activeCategory=='about_us'?styles['about-us--about']:''}
    // ${activeCategory=='our_vision'?styles['about-us--our-vision']:''}
    // ${activeCategory=='our_message'?styles['about-us--our-message']:''}
    // ${activeCategory=='our_values'?styles['about-us--our-values']:''}
    `} id='about-us'>
    {/* <div className={styles['global']}>
        {
            images&& images.map((img,index)=>(
                <img src={img} className={styles['global']}/>
            ))
        }
    </div> */}
    <img src={aboutUsImg} alt='section background image' className={`${styles['about-us__background-image']} 
    ${activeCategory=='about_us'?styles['about-us__background-image--active']:''}`}/>
    <img src={ourVisionBg2} alt='section background image' className={`${styles['about-us__background-image']} 
        ${activeCategory=='our_vision'?styles['about-us__background-image--active']:''}`}/>
    <img src={ourMessageBg} alt='section background image' className={`${styles['about-us__background-image']} 
        ${activeCategory=='our_message'?styles['about-us__background-image--active']:''}`}/>
    <img src={ourValuesBg} alt='section background image' className={`${styles['about-us__background-image']} 
        ${activeCategory=='our_values'?styles['about-us__background-image--active']:''}`}/>
    
        <Container>
            <Row>
                <Col lg='7'>
                    <h2 className={styles['about-us__title']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>{title}</h2>
                    <p className={styles['about-us__description']} data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800'>
                        {description}
                    </p>
                </Col>
                <Col lg='5' className={'d-none d-lg-block'}>
                    <div className={styles['about-us__icons-wrapper']}>
                        <div className={styles['about-us__icon-wrapper']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>
                            <button onClick={()=>{changeActiveCategory('our_vision')}} className={`${styles['about-us__icon-button']} ${activeCategory=='our_vision'?styles['about-us__icon-button--acitve']:''}`}>
                                <PlaneIcon className={styles['about-us__icon']}/>
                            </button>
                            <h3 className={styles['about-us__icon-title']}>{t('Our Vision')}</h3>
                        </div>
                        <div className={styles['about-us__icon-wrapper']} data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800'>
                            <button onClick={()=>{changeActiveCategory('our_message')}} className={`${styles['about-us__icon-button']} ${activeCategory=='our_message'?styles['about-us__icon-button--acitve']:''}`}>
                                <CloudIcon className={styles['about-us__icon']}/>
                            </button>
                            <h3 className={styles['about-us__icon-title']}>{t('Our Message')}</h3>
                        </div>
                        <div className={styles['about-us__icon-wrapper']} data-aos='fade-up' data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>
                            <button onClick={()=>{changeActiveCategory('our_values')}} className={`${styles['about-us__icon-button']} ${activeCategory=='our_values'?styles['about-us__icon-button--acitve']:''}`}>
                                <CirclesIcon className={styles['about-us__icon']}/>
                            </button>
                            <h3 className={styles['about-us__icon-title']}>{t('Our Values')}</h3>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    {/* </section> */}
    </section>
  )
}

export default AboutUs