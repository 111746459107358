import { CHANGE_BRANDS, CHANGE_CARS, CHANGE_GENERAL_SETTINGS, CHANGE_LANGUAGE, CHANGE_TYPES } from "./GlobalActionsTypes"

const initialState = {
    lang:'en',
    types:[],
    brands:[],
    generalSettings:[],
    cars:[],

}

const GlobalReducer = (state = initialState ,action)=>{
    switch (action.type){
        case CHANGE_LANGUAGE :{
            return {
                ...state,
                lang:action.lang
            }
        }
        case CHANGE_TYPES :{
            return {
                ...state,
                types:action.types
            }
        }
        case CHANGE_BRANDS :{
            return {
                ...state,
                brands:action.brands
            }
        }
        case CHANGE_GENERAL_SETTINGS :{
            return {
                ...state,
                generalSettings:action.generalSettings
            }
        }
        case CHANGE_CARS :{
            return {
                ...state,
                cars:action.cars
            }
        }
        default: return {state}
    }
}

export default GlobalReducer