import React, { useEffect, useState } from 'react'
import styles from './SideBar.module.css'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {ReactComponent as RightArrow} from 'assets/icons/rightArrow.svg'
// import whatsAppFooterLogo from 'assets/imgs/LogoWhatsAdsW.png'
import logoResponsive from 'assets/imgs/LogoWhatsAdsW.png'
// import logoResponsive from 'assets/imgs/whatsLogo.png'
import {ReactComponent as MessageIconn} from 'assets/icons/messageIcon.svg'
import {ReactComponent as PhoneIconn} from 'assets/icons/phoneIconn.svg'
import {ReactComponent as PinIconn} from 'assets/icons/pinIconn.svg'
import './SideBar.css'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'
import { useSelector } from 'react-redux'
import { emailConnect, whtsAppConnect } from 'utils/features'
import { HashLink } from 'react-router-hash-link'
function SideBar({isSideBarVisible,toggleSideNavBar,brands,types}) {
    const {t} =useTranslation()
    const [isbrandSectionActive,setIsbrandSectionActive]=useState(false)
    const [isTypeSectionActive,setIsTypeSectionActive]=useState(false)
    const navigate =useNavigate()
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
    },[selector])
    useEffect(()=>{
        toggleSideNavBar()
    },[navigate])
    return (
    <nav className={`${styles["sidebar"]} ${isSideBarVisible && styles['sidebar--open']}`}>
        <div className={styles["sidebar__content"]}>
            <div className='d-flex justify-content-between mb-3'>
                <div className={styles["sidebar__content-language"]}>
                    <ChangeLangueList/>
                </div>
                {/* <NavLink to='/' ><PavilionSmallLogo className={styles['sidebar__logo']}/></NavLink> */}
                <button role='button' onClick={()=>{toggleSideNavBar('close')}} className={styles['sidebar__close-button']}><RightArrow className={styles['sidebar__close-icon']}/></button>
            </div>
            <ul className={styles["sidebar__nav-list"]}>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <NavLink to='/' className={styles["sidebar__nav-link"]}>                    
                        <img src={logoResponsive} alt='logo' className={`${styles['sidebar__logo']}`}/>
                    </NavLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/' className={styles["sidebar__nav-link"]}>{t('Home')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/#about-us' className={styles["sidebar__nav-link"]}>{t('About Us')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/#what-we-offer' className={styles["sidebar__nav-link"]}>{t('Services')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/#our-projects' className={styles["sidebar__nav-link"]}>{t('Portfolio')}</HashLink>
                </li>
                {/* <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <NavLink to='/' className={styles["sidebar__nav-link"]}>{t('Blogs')}</NavLink>
                </li> */}
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <HashLink to='/#footer' className={styles["sidebar__nav-link"]}>{t('Contact Us')}</HashLink>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <a href={"https://www.google.com/maps?ll=25.184924,55.276903&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=The+Metropolis+Tower+Business+Bay+-+Dubai"} target='_blank' className={styles['sidebar__contact-item']}>
                        <PinIconn/>
                        <span className={styles['sidebar__contact-text']}>{settings?.location?settings?.location:'UAE, Dubai'}</span>
                    </a>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <button role='button' onClick={()=>{window.open(`tel:${settings?.contact_phone_number?settings?.contact_phone_number:'971502282086'}`, '_self')}} className={styles['sidebar__contact-item']}>
                        <PhoneIconn/>
                        <span className={styles['sidebar__contact-text']}>{settings?.contact_phone_number?settings?.contact_phone_number:'971502282086'}</span>
                    </button>
                </li>
                <li className={`${styles["sidebar__nav-item"]} sidebar__nav-item`}>
                    <a href={emailConnect(settings?.contact_email?settings?.contact_email:'info@whatsads.net')} className={styles['sidebar__contact-item']}>
                        <MessageIconn/>
                        <span className={styles['sidebar__contact-text']}>{settings?.contact_email?settings?.contact_email:'info@whatsads.net'}</span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
  )
}

export default SideBar