import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from './WhatWeOffer.module.css'
import {ReactComponent as TrueIcon} from 'assets/icons/trueIcon.svg'
import TrueIconSrc from 'assets/icons/trueIcon.svg'
import {ReactComponent as SocialMediaIcon} from 'assets/icons/SocialMediaIcon.svg'
import {ReactComponent as WebIcon} from 'assets/icons/WebIcon.svg'
import {ReactComponent as SeoIcon} from 'assets/icons/SeoIcon.svg'
import { useTranslation } from 'react-i18next'
function WhatWeOffer({categories}) {
  const {t} =useTranslation()
  return (
    <section className={styles['what-we-offer']} id='what-we-offer'>
        <Container className='h-100'>
            <Row className='h-100'>
                <Col lg='5' className='my-auto'>
                    <div>
                        <h2 className={styles['what-we-offer__title']} data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800'>{t('What we offer')}</h2>
                        <p className={styles['what-we-offer__description']} data-aos='fade-up' data-aos-once='true' data-aos-delay='200' data-aos-duration='800'>{t('WhatsAds  aProfessional Platform Dedicated to Business Growth.')}</p>
                        {/* <Link to='/' className={styles['what-we-offer__link']} data-aos='fade-up' data-aos-once='true' data-aos-delay='300' data-aos-duration='800'>{t('Our Services')}</Link> */}
                    </div>
                </Col>
                <Col lg='7'>
                    <div className={styles['what-we-offer__cards-wrapper']}>
                        {
                            categories&&categories.map((category,index)=>(
                                index<4&&<div data-aos='fade-up' data-aos-once='true' data-aos-delay='100' data-aos-duration='800' key={index}>
                                    <div className={styles['what-we-offer__card']} >
                                        <div className={styles['what-we-offer__card-icon-wrapper']}>
                                            <img src={category?.icon} alt='category icon' className={styles['what-we-offer__card-icon']} />
                                        </div>
                                        <div>
                                            <h3 className={styles['what-we-offer__card-title']}>{category?.title}</h3>
                                            <ul className={styles['what-we-offer__card-description-list']}>
                                                {
                                                    category?.features&&category?.features.map((feature,indexx)=>(
                                                        <li className={styles['what-we-offer__card-description']} key={indexx}>
                                                            <img src={TrueIconSrc} className={styles['what-we-offer__card-description-icon']}/> {feature?.feature}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default WhatWeOffer