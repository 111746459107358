import React, { useEffect, useRef, useState } from 'react'
import { Container, Navbar, NavDropdown } from 'react-bootstrap'
import styles from './NavBar.module.css'
import './Navbar.css'
import pavilionLogo from 'assets/imgs/whatsLogo.png'

import {ReactComponent as MenuIcon} from 'assets/icons/menuIcon.svg'
import {ReactComponent as MessageIcon} from 'assets/icons/messageIcon.svg'
import {ReactComponent as PhoneIcon} from 'assets/icons/phoneIcon.svg'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ChangeLangueList from 'components/Global/Elements/ChangeLangueList/ChangeLangueList'
import { useSelector } from 'react-redux'
import { emailConnect, ggtag, whtsAppConnect } from 'utils/features'
import { HashLink } from 'react-router-hash-link'
function NavBar({toggleSideNavBar}) {
    const {t} =useTranslation()
    const navbarRef=useRef(null)
    const navbarDropdownFirstRef=useRef(null)
    const navbarDropdownSecondRef=useRef(null)
    
    const [settings,setSettings]=useState([])
    const selector=useSelector(data=>data.GlobalReducer)
    useEffect(()=>{
        setSettings(selector.generalSettings)
    },[selector])
  return (
    <Navbar expand="xl" className={`${styles['navbar']}  `} ref={navbarRef} >
        <div className='d-flex flex-column w-100' data-aos='fade-up' data-aos-once='true'  data-aos-duration='800'>
            <div className={`${styles['navbar-top']} d-none d-xl-flex`}>
                <Container fluid='lg' className='container--header'>
                    <div className={styles['navbar-top__wrapper']}>
                        <div className={styles['navbar-top__contact-wrapper']}>
                            <NavLink to='/'>
                                <img src={pavilionLogo} alt='logo' className={styles['navbar__logo']}/>
                            </NavLink>
                            <div className='d-flex align-items-center'>
                                <ChangeLangueList/>
                            </div>
                            <button role='button' onClick={()=>{ggtag();window.open(`tel:${settings?.contact_phone_number?settings?.contact_phone_number:'+971 54 217 2270'}`, '_self')}} className={`${styles['navbar-top__contact-item']} ${styles['navbar-top__contact-item--margin']}`}>
                                <PhoneIcon/>
                                <span className={styles['navbar-top__contact-text']}>{settings?.contact_phone_number?settings?.contact_phone_number:'+971 54 217 2270'}</span>
                            </button>
                            <a onClick={ggtag} href={emailConnect(settings?.contact_email?settings?.contact_email:'info@whatsads.net')} className={`${styles['navbar-top__contact-item']} ${styles['navbar-top__contact-item--email']}`}>
                                <MessageIcon/>
                                <span className={styles['navbar-top__contact-text']}>{settings?.contact_email?settings?.contact_email:'info@whatsads.net'}</span>
                            </a>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={styles['navbar-bottom']}>
                <Container className='h-100 d-flex'>
                    <div className='h-100 d-flex d-xl-none align-items-center justify-content-between w-100'>
                        <NavLink to='/' className={`${styles['navbar-top__language-wrapper']} ${styles['navbar-top__language-wrapper--responsive']}`}>
                            <img src={pavilionLogo} alt='logo' className={styles['navbar__logo']}/>   
                        </NavLink>
                        <button role='button' className={`${styles['navbar__menu-btn']} `} onClick={toggleSideNavBar}>
                            <MenuIcon/>
                        </button>
                    </div>
                    <Navbar.Collapse className='h-100'>
                        <ul className={`${styles['navbar-nav']} navbar-nav w-100 h-100 d-flex align-items-center justify-content-center`}>
                            <li className={`${styles["navbar__menu-items"]} nav-item `}>
                                <HashLink smooth to='/' className={`${styles['navbar-menu-link']} nav-link`}>{t('Home')}</HashLink>
                            </li>
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <HashLink to='/#about-us' className={`${styles['navbar-menu-link']} nav-link`}>{t('About us')}</HashLink>
                            </li>
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <HashLink to='/#what-we-offer' className={`${styles['navbar-menu-link']} nav-link`}>{t('Services')}</HashLink>
                            </li>
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <HashLink to='/#our-projects' className={`${styles['navbar-menu-link']} nav-link`}>{t('Portfolio')}</HashLink>
                            </li>
                            {/* <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <NavLink to='/' className={`${styles['navbar-menu-link']} nav-link`}>{t('Products')}</NavLink>
                            </li>
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <NavLink to='/' className={`${styles['navbar-menu-link']} nav-link`}>{t('Blogs')}</NavLink>
                            </li> */}
                            <li className={`${styles["navbar__menu-items"]} nav-item`}>
                                <HashLink to='/#footer' className={`${styles['navbar-menu-link']} nav-link`}>{t('Contact Us')}</HashLink>
                            </li>
                        </ul> 
                    </Navbar.Collapse>
                </Container>
            </div>

        </div>


    </Navbar>
  )
}

export default NavBar