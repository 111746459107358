// import WhyUsCard from 'components/Global/Elements/WhyUsCard/WhyUsCard'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import styles from './WhyUs.module.css'
import {ReactComponent as PhoneSettingIcon} from 'assets/icons/phoneSettingIcon.svg'
import {ReactComponent as PersonalizeIcon} from 'assets/icons/personalizeIcon.svg'
import {ReactComponent as RichIcon} from 'assets/icons/richIcon.svg'
import { HashLink } from 'react-router-hash-link'
function WhyUs() {
  const {t} =useTranslation()
  return (
    <section className={styles['why-us']}>
        <Container>
            <h2 className={styles['why-us__title']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>{t("Why use WhatsAds for marketing your business?")}</h2>
            <p className={styles['why-us__description']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='200' data-aos-once='true'>{t('Reach Your Goals Faster with Highly Targeted Campaigns on WhatsApp Get the Most Out of Our Wide Range of Services and Features.')}</p>
            <div className={styles['why-us__cards-wrapper']}>
                <div className={styles['why-us__card-wrapper']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                    <PhoneSettingIcon className={styles['why-us__card-icon']}/>
                    <h3 className={styles['why-us__card-title']}>{t('The highest return on investment')}</h3>
                    <p className={styles['why-us__card-description']}>{t('Our innovative service pays for itself through sales and savings on your marketing budget. We can provide a bespoke approach whatever the size of your business.')}</p>
                </div>
                <div className={styles['why-us__card-wrapper']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='200' data-aos-once='true'>
                    <PersonalizeIcon className={styles['why-us__card-icon']}/>
                    <h3 className={styles['why-us__card-title']}>{t('Build your brand name')}</h3>
                    <p className={styles['why-us__card-description']}>{t('With every message done in your business name, our service will help to build a lasting impression of your brand and maximise your revenue going forward')}</p>
                </div>
                <div className={styles['why-us__card-wrapper']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='300' data-aos-once='true'>
                    <RichIcon className={styles['why-us__card-icon']}/>
                    <h3 className={styles['why-us__card-title']}>{t('Achieve Maximum Results')}</h3>
                    <p className={styles['why-us__card-description']}>{t('WhatsAds is The Right Solution for Your Business Needs to Achieve Maximum Results and Leverage the Power of WhatsApp')}</p>
                </div>
            </div>
            <HashLink to='/#contact-us' className={styles['header__feature-item']} data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                {t('Ask For Consultation')}
            </HashLink>
            {/* <Row>
                <Col xl='5'>
                    <Row >
                        <Col xl='12' data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                            <WhyUsCard />
                        </Col>
                        <Col xl='12' data-aos='fade-up' data-aos-duration='800' data-aos-delay='200' data-aos-once='true'>
                            <WhyUsCard />
                        </Col>
                        <Col xl='12' data-aos='fade-up' data-aos-duration='800' data-aos-delay='300' data-aos-once='true'>
                            <WhyUsCard />
                        </Col>
                    </Row>
                </Col>
                <Col xl='7'>
                    <Row>
                        <Col xl='12' data-aos='fade-up' data-aos-duration='800' data-aos-delay='100' data-aos-once='true'>
                            <WhyUsCard big={true}/>
                        </Col>
                        <Col xl='12' data-aos='fade-up' data-aos-duration='800' data-aos-delay='200' data-aos-once='true'>
                            <WhyUsCard big={true}/>
                        </Col>
                        <Col xl='12' data-aos='fade-up' data-aos-duration='800' data-aos-delay='300' data-aos-once='true'>
                            <WhyUsCard big={true}/>
                        </Col>
                    </Row>
                </Col>
            </Row> */}
        </Container>
    </section>
  )
}

export default WhyUs